var main = (function($) {
  function init() {
  	$('.flexslider').flexslider();
  	
  	$('.js-toggle-site-nav').on( "click", function(event) {
     event.preventDefault();
     $(this).toggleClass('open');
     $('.main-menu-area').toggle();
   });
  	
  }
   
 return {
    init: init
  };
}(jQuery));

